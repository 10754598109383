<template>
  <v-card
    flat
    tile
    color="grey"
    class="pt-180"
    height="100vh"
  >
    <v-row
      no-gutters
      align="center"
      justify="center"
    >
      <v-flex
        xs4
        text-center
      >
        <v-card
          flat
          tile
          width="100%"
          color="white"
          class="mt-16 pa-5"
        >
          <v-card-text class="text-h6 text-left text--primary">
            <AlertBox
              :message="message"
              :is-error="isError"
              class="ma-1 mb-2"
            />
          </v-card-text>
          <v-card-text class="text-h6 text-left text--primary">
            {{ $t("email_verify_txt") }}
          </v-card-text>
          <v-card-text>
            <v-form ref="emailVerification">
              <v-text-field
                v-model="email"
                :placeholder="$t('email_address')"
                outlined
                required
                :rules="rules.email"
              />
              <!-- v-if="isHide" -->
              <v-btn
                class="ml-5"
                color="primary"
                :loading="loading"
                @click="verifyEmail()"
              >
                {{ $t("verify") }}
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-row>
  </v-card>
</template>

<script>
// utils
import { rules } from '@/utils/validation'
import i18n  from '@/utils/i18n'

// graphql
import { EMAIL_ACTIVATION } from "@/graphql/authentication";

export default {
  name: "EmailVerification",
  data() {
    return {
      message: null,
      email: null,
      rules,
      loading: false,
      isError: true,
      isHide: false,
    }
  },
  methods: { 
    /**
     * Verify Email
     */
    async verifyEmail() {
      const { token } = this.$route.query
      this.error = null
      if (this.$refs.emailVerification.validate() && token) {
        this.loading = true
        try {
          const { data: {emailActivate} } = await this.$apollo.mutate({
            mutation: EMAIL_ACTIVATION,
            variables: {
              activationInput: {
                email: this.email,
                code: token,
              },
            },
          });

          if (emailActivate.success) {
            this.isError = false
            this.message = i18n.t('email_verified')
            this.loading = false
            this.isHide = true
          } else {
            this.message = this.$t(emailActivate.error)
            this.loading = false
          }
        } catch (e) {
          console.log(e);
          this.message = e
          this.loading = false
        }
       
      }
    },
  },
};
</script>